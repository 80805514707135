* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #4252cb;
}

.app {
  background-color: #080808;
  color: white;
  min-height: 100vh;
  max-width: 100vw;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
}
.goog-te-combo {
  outline: none;
  border: none;
  padding: 10px;
  width: 12vw;
  box-shadow: 0 0 3px rgb(121, 120, 120);
  background-color: #e0e0e0;
}
.goog-te-gadget {
  color: transparent !important;
  margin-bottom: -15px;
  margin-right: 8px;
}
/* .goog-te-gadget img {
  display: none;
}
.goog-logo-link {
  display: none;
} */

.social-btns .btn,
.social-btns .btn:before {
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.social-btns .btn:before {
  top: 65%;
  left: -100%;
}
.social-btns .btn.twitter:before,
.social-btns .btn.telegram:before {
  background-color: #4252cb;
}

.social-btns .btn:focus,
.social-btns .btn:hover {
  transform: scale(1.1);
}
.social-btns {
  height: 10px;
  margin: auto;
  text-align: center;
  position: fixed;
  /* right: 18px; */
  right: -10px;
  bottom: 200px;
}
.social-btns .btn {
  display: block;
  background-color: #fff;
  width: 60px;
  height: 35px;
  line-height: 50px;
  margin: 25px 8px;
  position: relative;
  overflow: hidden;
  border-radius: 20% 0 0 20%;
  box-shadow: 0 0 6px white;
  opacity: 0.99;
}
.social-btns .btn:before {
  content: "";
  width: 190%;
  height: 120%;
  position: absolute;
  transform: rotate(45deg);
}
.telegramIcon {
  margin-left: -4px;
  margin-top: 8px;
}
.twitterIcon {
  margin-top: 8px;
}
